import React from 'react'
import PageLayout from '../components/PageLayout';
import ProjectCoverListContainer from '../containers/ProjectCoverListContainer';

function Music() {
  return (
    <PageLayout title="Music" uppercaseTitle titleAnim>
      <ProjectCoverListContainer category="music"/>
    </PageLayout>
  );
}

export default Music;
